var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import FileOutlined from '@ant-design/icons/FileOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import FolderOutlined from '@ant-design/icons/FolderOutlined';
import classNames from 'classnames';
import { conductExpandParent } from 'rc-tree/lib/util';
import { convertDataToEntities, convertTreeToData } from 'rc-tree/lib/utils/treeUtil';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Tree from './Tree';
import { calcRangeKeys, convertDirectoryKeysToNodes } from './utils/dictUtil';
function getIcon(props) {
    const { isLeaf, expanded } = props;
    if (isLeaf) {
        return React.createElement(FileOutlined, null);
    }
    return expanded ? React.createElement(FolderOpenOutlined, null) : React.createElement(FolderOutlined, null);
}
function getTreeData({ treeData, children }) {
    return treeData || convertTreeToData(children);
}
const DirectoryTree = (_a, ref) => {
    var { defaultExpandAll, defaultExpandParent, defaultExpandedKeys } = _a, props = __rest(_a, ["defaultExpandAll", "defaultExpandParent", "defaultExpandedKeys"]);
    // Shift click usage
    const lastSelectedKey = React.useRef();
    const cachedSelectedKeys = React.useRef();
    const treeRef = React.createRef();
    React.useImperativeHandle(ref, () => treeRef.current);
    const getInitExpandedKeys = () => {
        const { keyEntities } = convertDataToEntities(getTreeData(props));
        let initExpandedKeys;
        // Expanded keys
        if (defaultExpandAll) {
            initExpandedKeys = Object.keys(keyEntities);
        }
        else if (defaultExpandParent) {
            initExpandedKeys = conductExpandParent(props.expandedKeys || defaultExpandedKeys || [], keyEntities);
        }
        else {
            initExpandedKeys = (props.expandedKeys || defaultExpandedKeys);
        }
        return initExpandedKeys;
    };
    const [selectedKeys, setSelectedKeys] = React.useState(props.selectedKeys || props.defaultSelectedKeys || []);
    const [expandedKeys, setExpandedKeys] = React.useState(() => getInitExpandedKeys());
    React.useEffect(() => {
        if ('selectedKeys' in props) {
            setSelectedKeys(props.selectedKeys);
        }
    }, [props.selectedKeys]);
    React.useEffect(() => {
        if ('expandedKeys' in props) {
            setExpandedKeys(props.expandedKeys);
        }
    }, [props.expandedKeys]);
    const onExpand = (keys, info) => {
        var _a;
        if (!('expandedKeys' in props)) {
            setExpandedKeys(keys);
        }
        // Call origin function
        return (_a = props.onExpand) === null || _a === void 0 ? void 0 : _a.call(props, keys, info);
    };
    const onSelect = (keys, event) => {
        var _a;
        const { multiple } = props;
        const { node, nativeEvent } = event;
        const { key = '' } = node;
        const treeData = getTreeData(props);
        // const newState: DirectoryTreeState = {};
        // We need wrap this event since some value is not same
        const newEvent = Object.assign(Object.assign({}, event), { selected: true });
        // Windows / Mac single pick
        const ctrlPick = (nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.ctrlKey) || (nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.metaKey);
        const shiftPick = nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.shiftKey;
        // Generate new selected keys
        let newSelectedKeys;
        if (multiple && ctrlPick) {
            // Control click
            newSelectedKeys = keys;
            lastSelectedKey.current = key;
            cachedSelectedKeys.current = newSelectedKeys;
            newEvent.selectedNodes = convertDirectoryKeysToNodes(treeData, newSelectedKeys);
        }
        else if (multiple && shiftPick) {
            // Shift click
            newSelectedKeys = Array.from(new Set([
                ...(cachedSelectedKeys.current || []),
                ...calcRangeKeys({
                    treeData,
                    expandedKeys,
                    startKey: key,
                    endKey: lastSelectedKey.current,
                }),
            ]));
            newEvent.selectedNodes = convertDirectoryKeysToNodes(treeData, newSelectedKeys);
        }
        else {
            // Single click
            newSelectedKeys = [key];
            lastSelectedKey.current = key;
            cachedSelectedKeys.current = newSelectedKeys;
            newEvent.selectedNodes = convertDirectoryKeysToNodes(treeData, newSelectedKeys);
        }
        (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, newSelectedKeys, newEvent);
        if (!('selectedKeys' in props)) {
            setSelectedKeys(newSelectedKeys);
        }
    };
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, showIcon = true, expandAction = 'click' } = props, otherProps = __rest(props, ["prefixCls", "className", "showIcon", "expandAction"]);
    const prefixCls = getPrefixCls('tree', customizePrefixCls);
    const connectClassName = classNames(`${prefixCls}-directory`, {
        [`${prefixCls}-directory-rtl`]: direction === 'rtl',
    }, className);
    return (React.createElement(Tree, Object.assign({ icon: getIcon, ref: treeRef, blockNode: true }, otherProps, { showIcon: showIcon, expandAction: expandAction, prefixCls: prefixCls, className: connectClassName, expandedKeys: expandedKeys, selectedKeys: selectedKeys, onSelect: onSelect, onExpand: onExpand })));
};
const ForwardDirectoryTree = React.forwardRef(DirectoryTree);
if (process.env.NODE_ENV !== 'production') {
    ForwardDirectoryTree.displayName = 'DirectoryTree';
}
export default ForwardDirectoryTree;
